import { Router } from '@angular/router';
import { AppService } from './app.service';
import { DecimalPipe } from './shared/decimal.pipe';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

declare const Mangler: any;

@Injectable()
export class ApiService {

	public = {
		login: (email, password, rememberme, token2fa, done, fail = null) => this.post('public/login', { email, password, rememberme, token2fa }, done, fail),
		logout: (done, fail = null) => this.get('public/logout', done, fail),
		resetPassword: (email, done, fail = null) => this.post('public/reset_password', { email }, done, fail),
		checkResetToken: (token, done, fail = null) => this.get('public/check_reset_token?token=' + (token || ''), done, fail),
		updatePassword: (data, done, fail = null) => this.post('public/update_password', data, done, fail),
		checkReferralCode: (code, done, fail = null) => this.get('public/check_referral_code?code=' + code, done, fail),
		signUp: (data, done, fail = null) => this.post('public/sign_up', data, done, fail),
		signUpDetails: (id, hash, done, fail = null) => this.get('public/sign_up_details?id=' + id + '&hash=' + hash, done, fail),
		signUpVerify: (data, done, fail = null) => this.post('public/sign_up_verify', data, done, fail),
		inviteDetails: (data, done, fail = null) => this.post('public/invite_details', data, done, fail),
		acceptInviteSignedIn: (data, done, fail = null) => this.post('public/accept_invite_signed_in', data, done, fail),
		acceptInviteExisting: (data, done, fail = null) => this.post('public/accept_invite_existing', data, done, fail),
		acceptInviteNew: (data, done, fail = null) => this.post('public/accept_invite_new', data, done, fail),
		getSupplierAccountInfo: (referralCode, done, fail = null) => this.get('public/get_supplier_account_info?referral_code=' + referralCode, done, fail),
		createSupplierAccount: (data, done, fail = null) => this.post('public/create_supplier_account', data, done, fail),
		invoiceInfo: (id, token, done, fail = null) => this.get('public/invoice_info?id=' + id + '&token=' + token, done, fail),
		invoiceCheckout: (id, token, done, fail = null) => this.get('public/invoice_checkout?id=' + id + '&token=' + token, done, fail),
		resetSnaggPassword: (email, done, fail = null) => this.post('public/reset_snagg_password', { email }, done, fail),

		proposalInfo: (id, token, done, fail = null) => this.get('public/proposal_info?id=' + id + '&token=' + token, done, fail),
		proposalTheme: (id, token, done, fail = null) => this.get('public/proposal_theme?id=' + id + '&token=' + token, done, fail),
		proposalAddComment: (data, done, fail = null) => this.post('public/proposal_add_comment', data, done, fail),
		proposalAccept: (data, done, fail = null) => this.post('public/proposal_accept', data, done, fail),
		proposalViewing: (id, token, done, fail = null) => this.get('public/proposal_viewing?id=' + id + '&token=' + token, done, fail),
		proposalChangeOption: (data, done, fail = null) => this.post('public/proposal_change_option', data, done, fail),
		proposalChangePaymentOption: (data, done, fail = null) => this.post('public/proposal_change_payment_option', data, done, fail),
		proposalGetLineQuantity: (data, done, fail = null) => this.post('public/proposal_get_line_quantity', data, done, fail),
		proposalUpdateLineQuantity: (data, done, fail = null) => this.post('public/proposal_update_line_quantity', data, done, fail),

		changeOrderInfo: (id, token, done, fail = null) => this.get('public/change_order_info?id=' + id + '&token=' + token, done, fail),
		changeOrderAddComment: (data, done, fail = null) => this.post('public/change_order_add_comment', data, done, fail),
		changeOrderAccept: (data, done, fail = null) => this.post('public/change_order_accept', data, done, fail),
		changeOrderViewing: (id, token, done, fail = null) => this.get('public/change_order_viewing?id=' + id + '&token=' + token, done, fail)
	};

	general = {
		ping: (done = null, fail = null) => this.get('general/ping', done, fail),
		getUserInfo: (done, fail = null) => this.get('general/get_user_info', done, fail),
		listCountries: (done, fail = null) => this.get('general/list_countries', done, fail)
	};

	account = {
		listPackages: (done, fail = null) => this.get(':org/account/list_packages', done, fail),
		stripe: (done, fail = null) => this.get(':org/account/stripe', done, fail),
		updateCompanyDetails: (data, done, fail = null) => this.post(':org/account/update_company_details', data, done, fail),
		orderPreview: (data, done, fail = null) => this.post(':org/account/order_preview', data, done, fail),
		activate: (data, done, fail = null) => this.post(':org/account/activate', data, done, fail),
		isActivated: (done, fail = null) => this.get(':org/account/is_activated', done, fail),
		cleanUp: (mode, done, fail = null) => this.get(':org/account/clean_up?mode=' + mode, done, fail),
		accountManagement: (id, done, fail = null) => this.get(':org/account/account_management?id=' + id, done, fail),
		applyAccountManagement: (data, done, fail = null) => this.post(':org/account/apply_account_management', data, done, fail),
		payByNewCard: (done, fail = null) => this.get(':org/account/pay_by_new_card', done, fail),
		payBySavedCard: (done, fail = null) => this.get(':org/account/pay_by_saved_card', done, fail),
		removeSavedCard: (done, fail = null) => this.get(':org/account/remove_saved_card', done, fail),
		waitForIntent: (id, done, fail = null) => this.get(':org/account/wait_for_intent?id=' + id, done, fail),
		upgradeAccount: (packageId, done, fail = null) => this.get(':org/account/upgrade_account?id=' + packageId, done, fail)
	};

	organisation = {
		getInfo: (done, fail = null) => this.get(':org/organisation/get_info', done, fail),
		extendTrial: (done, fail = null) => this.get(':org/organisation/extend_trial', done, fail),
		details: (done, fail = null) => this.get(':org/organisation/details', done, fail),
		list: (done, fail = null) => this.get(':org/organisation/list', done, fail),
		listAll: (options, done, fail = null) => this.post(':org/organisation/list_all', options, done, fail),
		setMetricsExcluded: (data, done, fail = null) => this.post(':org/organisation/set_metrics_excluded', data, done, fail),
		setDefault: (id, done, fail = null) => this.get(':org/organisation/set_default?id=' + id, done, fail),
		navigation: (done, fail = null) => this.get(':org/organisation/navigation', done, fail),
		quoteTarget: (done, fail = null) => this.get(':org/organisation/quote_target', done, fail),
		saveQuoteTarget: (quote_target, done, fail = null) => this.post(':org/organisation/save_quote_target', { quote_target }, done, fail),
		reportBug: (message, url, done, fail = null) => this.post(':org/organisation/report_bug', { message, url }, done, fail),
		registerReferralCode: (code, done, fail = null) => this.get(':org/organisation/register_referral_code?code=' + code, done, fail),
		referrals: (filters, done, fail = null) => this.post(':org/organisation/referrals', filters, done, fail),
		endSetup: (done, fail = null) => this.get(':org/organisation/end_setup', done, fail),
		tutorialStart: (done, fail = null) => this.get(':org/organisation/tutorial_start', done, fail),
		tutorialStop: (done, fail = null) => this.get(':org/organisation/tutorial_stop', done, fail),
		tutorialUpdate: (stepsComplete, done, fail = null) => this.post(':org/organisation/tutorial_update', stepsComplete, done, fail),
		runIntegration: (done, fail = null) => this.get(':org/organisation/run_integration', done, fail),
		dateRanges: (done, fail = null) => this.get(':org/organisation/date_ranges', done, fail),
		saveApiKey: (data, done, fail = null) => this.post(':org/organisation/save_api_key', data, done, fail),
		getApiKeys: (done, fail = null) => this.get(':org/organisation/get_api_keys', done, fail),
		revokeApiKey: (data, done, fail = null) => this.post(':org/organisation/revoke_api_key', data, done, fail),
		listFonts: (done, fail = null) => this.get(':org/organisation/list_fonts', done, fail),
		getTotalUsers: (done, fail = null) => this.get(':org/organisation/get_total_users', done, fail),
		getSnaggTotals: (done, fail = null) => this.get(':org/organisation/get_snagg_totals', done, fail),
		getOrgWithNoCard: (done, fail = null) => this.get(':org/organisation/get_org_with_no_card', done, fail),
		overview: (options, done, fail = null) => this.post(':org/organisation/overview', options || {}, done, fail)
	};

	quote = {
		list: (options, done, fail = null) => this.post(':org/quote/list', options || {}, done, fail),
		headerSummary: (id, done, fail = null) => this.get(':org/quote/header_summary?id=' + id, done, fail),
		costSummary: (id, done, fail = null) => this.get(':org/quote/cost_summary?id=' + id, done, fail),
		details: (id, done, fail = null) => this.get(':org/quote/details?id=' + id, done, fail),
		dismissCostWarning: (id, done, fail = null) => this.get(':org/quote/dismiss_cost_warning?id=' + id, done, fail),
		summary: (id, done, fail = null) => this.get(':org/quote/summary?id=' + id, done, fail),
		save: (record, done, fail = null) => this.post(':org/quote/save', record, done, fail),
		archive: (id, done, fail = null) => this.get(':org/quote/archive?id=' + id, done, fail),
		unarchive: (id, done, fail = null) => this.get(':org/quote/unarchive?id=' + id, done, fail),
		changeStage: (id, stage, done, fail = null) => this.get(':org/quote/change_stage?id=' + id + '&stage=' + stage, done, fail),
		changeTitle: (id, title, done, fail = null) => this.post(':org/quote/change_title', { id, title }, done, fail),
		lines: (options, done, fail = null) => this.post(':org/quote/lines', options, done, fail),
		unsyncedLines: (id, done, fail = null) => this.get(':org/quote/unsynced_lines?id=' + id, done, fail),
		unsyncedDetails: (id, done, fail = null) => this.get(':org/quote/unsynced_details?id=' + id, done, fail),
		syncLines: (data, done, fail = null) => this.post(':org/quote/sync_lines', data, done, fail),
		increaseLine: (data, done, fail = null) => this.post(':org/quote/increase_line', data, done, fail),
		deleteLine: (id, done, fail = null) => this.get(':org/quote/delete_line?id=' + id, done, fail),
		saveLine: (data, done, fail = null) => this.post(':org/quote/save_line', data, done, fail),
		newLine: (data, done, fail = null) => this.post(':org/quote/new_line', data, done, fail),
		getLine: (id, done, fail = null) => this.get(':org/quote/get_line?id=' + id, done, fail),
		priceAdjustments: (id, done, fail = null) => this.get(':org/quote/price_adjustments?id=' + id, done, fail),
		savePriceAdjustments: (id, data, done, fail = null) => this.post(':org/quote/save_price_adjustments?id=' + id, data, done, fail),
		addLine: (data, done, fail = null) => this.post(':org/quote/add_line', data, done, fail),
		newStructure: (data, done, fail = null) => this.post(':org/quote/new_structure', data, done, fail),
		getStructure: (id, done, fail = null) => this.get(':org/quote/get_structure?id=' + id, done, fail),
		saveStructure: (data, done, fail = null) => this.post(':org/quote/save_structure', data, done, fail),
		saveMultiStructure: (data, done, fail = null) => this.post(':org/quote/save_multi_structure', data, done, fail),
		deleteStructure: (id, done, fail = null) => this.get(':org/quote/delete_structure?id=' + id, done, fail),
		updateStructureOrder: (data, done, fail = null) => this.post(':org/quote/update_structure_order', data, done, fail),
		updateStructureParent: (id, parentId, done, fail = null) => this.get(':org/quote/update_structure_parent?id=' + id + '&parent=' + parentId, done, fail),
		clone: (data, done, fail = null) => this.post(':org/quote/clone', data, done, fail),
		getCategories: (id, done, fail = null) => this.get(':org/quote/get_categories?id=' + id, done, fail),
		updateCategories: (id, add, remove, done, fail = null) => this.post(':org/quote/update_categories', { id, add, remove }, done, fail),
		updateCategoryOrder: (data, done, fail = null) => this.post(':org/quote/update_category_order', data, done, fail),
		new: (data, done, fail = null) => this.post(':org/quote/new', data, done, fail),
		revisions: (id, done, fail = null) => this.get(':org/quote/revisions?id=' + id, done, fail),
		getDefaultExpiryDate: (id, done, fail = null) => this.get(':org/quote/get_default_expiry_date?id=' + id, done, fail),
		markAsSent: (record, done, fail = null) => this.post(':org/quote/mark_as_sent', record, done, fail),
		markAsAccepted: (record, done, fail = null) => this.post(':org/quote/mark_as_accepted', record, done, fail),
		downloadQuota: (id, done, fail = null) => this.get(':org/quote/download_quota?id=' + id, done, fail),
		getAreas: (id, done, fail = null) => this.get(':org/quote/get_areas?id=' + id, done, fail),

		listTemplates: (id, done, fail = null) => this.get(':org/quote/list_templates?id=' + id, done, fail),
		addTemplate: (quoteId, templateId, done, fail = null) => this.post(':org/quote/add_template', { quote_id: quoteId, template_id: templateId }, done, fail),
		getTemplate: (id, done, fail = null) => this.get(':org/quote/get_template?id=' + id, done, fail),
		saveTemplate: (data, done, fail = null) => this.post(':org/quote/save_template', data, done, fail),
		deleteTemplate: (id, done, fail = null) => this.get(':org/quote/delete_template?id=' + id, done, fail),

		overview: (options, done, fail = null) => this.post(':org/quote/overview', options || {}, done, fail),

		cloneStructureInfo: (id, done, fail = null) => this.get(':org/quote/clone_structure_info?id=' + id, done, fail),
		cloneStructure: (data, done, fail = null) => this.post(':org/quote/clone_structure', data, done, fail),

		getSubsystemOrder: (data, done, fail = null) => this.post(':org/quote/get_subsystem_order', data, done, fail),
		saveSubsystemOrder: (data, done, fail = null) => this.post(':org/quote/save_subsystem_order', data, done, fail),
		resetSubsystemOrder: (data, done, fail = null) => this.post(':org/quote/reset_subsystem_order', data, done, fail),
		getStructureOrder: (data, done, fail = null) => this.post(':org/quote/get_structure_order', data, done, fail),
		saveStructureOrder: (data, done, fail = null) => this.post(':org/quote/save_structure_order', data, done, fail),
		resetStructureOrder: (data, done, fail = null) => this.post(':org/quote/reset_structure_order', data, done, fail),
		getAccessoryOrder: (data, done, fail = null) => this.post(':org/quote/get_accessory_order', data, done, fail),
		saveAccessoryOrder: (data, done, fail = null) => this.post(':org/quote/save_accessory_order', data, done, fail),
		resetAccessoryOrder: (data, done, fail = null) => this.post(':org/quote/reset_accessory_order', data, done, fail),

		vatRates: (id, done, fail = null) => this.get(':org/quote/vat_rates?id=' + id, done, fail),
		saveVatRates: (data, done, fail = null) => this.post(':org/quote/save_vat_rates', data, done, fail),

		sendToZoho: (id, done, fail = null) => this.get(':org/quote/send_to_zoho?id=' + id, done, fail),

		newOption: (quote_id, parent_id, done, fail = null) => this.post(':org/quote/new_option', { quote_id, parent_id }, done, fail),
		getOption: (id, done, fail = null) => this.get(':org/quote/get_option?id=' + id, done, fail),
		saveOption: (data, done, fail = null) => this.post(':org/quote/save_option', data, done, fail),
		deleteOption: (id, done, fail = null) => this.get(':org/quote/delete_option?id=' + id, done, fail),
		changeOptionParent: (id, parent_id, done, fail = null) => this.post(':org/quote/change_option_parent', { id, parent_id }, done, fail),
		updateOptionOrder: (quote_id, options, done, fail = null) => this.post(':org/quote/update_option_order', { quote_id, options }, done, fail),
		setOptionState: (id, state, done, fail = null) => this.post(':org/quote/set_option_state', { id, state }, done, fail),
		addOptionLine: (optionId, lineIds, done, fail = null) => this.post(':org/quote/add_option_line', { optionId, lineIds }, done, fail),
		removeOptionLine: (optionId, lineIds, done, fail = null) => this.post(':org/quote/remove_option_line', { optionId, lineIds }, done, fail),

		linesByProduct: (id, done, fail = null) => this.get(':org/quote/lines_by_product?id=' + id, done, fail),
		bulkLineOperation: (data, done, fail = null) => this.post(':org/quote/bulk_line_operation', data, done, fail),
		validateSelectedLines: (quoteId, lineIds, done, fail = null) => this.post(':org/quote/validate_selected_lines', { id: quoteId, lines: lineIds }, done, fail),

		earliestQuoteDate: (done, fail = null) => this.get(':org/quote/earliest_quote_date', done, fail),

		submitForReview: (id, done, fail = null) => this.get(':org/quote/submit_for_review?id=' + id, done, fail),
		markAsPassedReview: (id, done, fail = null) => this.get(':org/quote/mark_as_passed_review?id=' + id, done, fail),
		submitReviewResult: (data, done, fail = null) => this.post(':org/quote/submit_review_result', data, done, fail),

		addOptionToLibrary: (id, library_name, is_public, done, fail = null) => this.post(':org/quote/add_option_to_library', { id, library_name, is_public }, done, fail),
		addOptionFromLibrary: (id, quote_id, done, fail = null) => this.post(':org/quote/add_option_from_library', { id, quote_id }, done, fail),
		copyOptionToMyLibrary: (id, done, fail = null) => this.post(':org/quote/copy_option_to_my_library', { id }, done, fail),
		copyOptionToMarketplace: (id, done, fail = null) => this.post(':org/quote/copy_option_to_marketplace', { id }, done, fail),
		listOptionLibrary: (data, done, fail = null) => this.post(':org/quote/list_option_library', data, done, fail),
		deleteOptionFromLibrary: (id, done, fail = null) => this.get(':org/quote/delete_option_from_library?id=' + id, done, fail),
		cloneOptionToAreas: (data, done, fail = null) => this.post(':org/quote/clone_option_to_areas', data, done, fail),

		newOptionLibrary: (parent_id, done, fail = null) => this.post(':org/quote/new_optionlibrary', { parent_id }, done, fail),
		getOptionLibrary: (id, done, fail = null) => this.get(':org/quote/get_optionlibrary?id=' + id, done, fail),
		saveOptionLibrary: (data, done, fail = null) => this.post(':org/quote/save_optionlibrary', data, done, fail),
		deleteOptionLibrary: (id, done, fail = null) => this.get(':org/quote/delete_optionlibrary?id=' + id, done, fail),
		changeOptionLibraryParent: (id, parent_id, done, fail = null) => this.post(':org/quote/change_optionlibrary_parent', { id, parent_id }, done, fail),
		updateOptionLibraryOrder: (options, done, fail = null) => this.post(':org/quote/update_optionlibrary_order', { options }, done, fail),
		setOptionLibraryState: (id, state, done, fail = null) => this.post(':org/quote/set_optionlibrary_state', { id, state }, done, fail),

		updateProjectCounter: (data, done, fail = null) => this.post(':org/quote/update_project_counter', data, done, fail),
		setProject: (data, done, fail = null) => this.post(':org/quote/set_project', data, done, fail),

		changes: (data, done, fail = null) => this.post(':org/quote/changes', data, done, fail),
		changeOrder: (id, done, fail = null) => this.get(':org/quote/change_order?id=' + id, done, fail),
		saveChangeOrder: (data, done, fail = null) => this.post(':org/quote/save_change_order', data, done, fail),
		issues: (id, done, fail = null) => this.get(':org/quote/issues?id=' + id, done, fail),

		listPaymentOptions: (done, fail = null) => this.get(':org/quote/list_payment_options', done, fail),
		getPaymentOption: (id, done, fail = null) => this.get(':org/quote/get_payment_option?id=' + id, done, fail),
		newPaymentOption: (done, fail = null) => this.get(':org/quote/new_payment_option', done, fail),
		savePaymentOption: (data, done, fail = null) => this.post(':org/quote/save_payment_option', data, done, fail),
		testPaymentOption: (data, done, fail = null) => this.post(':org/quote/test_payment_option', data, done, fail),
		deletePaymentOption: (id, done, fail = null) => this.get(':org/quote/delete_payment_option?id=' + id, done, fail),

		getShareSettings: (id, done, fail = null) => this.get(':org/quote/get_share_settings?id=' + id, done, fail),
		saveShareSettings: (data, done, fail = null) => this.post(':org/quote/save_share_settings', data, done, fail),
		checkShareImport: (shareCode, done, fail = null) => this.get(':org/quote/check_share_import?share_code=' + shareCode, done, fail),
		getShareImport: (shareCode, done, fail = null) => this.get(':org/quote/get_share_import?share_code=' + shareCode, done, fail),
		saveShareImport: (data, done, fail = null) => this.post(':org/quote/save_share_import', data, done, fail),
		runShareImport: (shareCode, done, fail = null) => this.get(':org/quote/run_share_import?share_code=' + shareCode, done, fail),

		finaliseOrder: (data, done, fail = null) => this.post(':org/quote/finalise_order', data, done, fail),

		importCSVByFile: (data, done, fail = null) => this.post(':org/quote/import_csv_by_file', data, done, fail),
		fetchQuoteImportData: (id, done, fail = null) => this.get(':org/quote/fetch_quote_import_data?id=' + id, done, fail),
		saveCSVImport: (data, done, fail = null) => this.post(':org/quote/save_csv_import', data, done, fail),
		runCSVImport: (data, done, fail = null) => this.post(':org/quote/run_csv_import', data, done, fail),
		fetchCategories: (done, fail = null) => this.get(':org/quote/fetch_categories', done, fail),
		fetchLabourTypes: (done, fail = null) => this.get(':org/quote/fetch_labour_types', done, fail),
		removeProductMapping: (data, done, fail = null) => this.post(':org/quote/remove_product_mapping', data, done, fail),
		addProductMapping: (data, done, fail = null) => this.post(':org/quote/add_product_mapping', data, done, fail),
		fetchProductList: (data, done, fail = null) => this.post(':org/quote/fetch_product_list', data, done, fail)
	};

	project = {
		get: (id, done, fail = null) => this.get(':org/project/get?id=' + id, done, fail),
		new: (customerId, done, fail = null) => this.get(':org/project/new?customer_id=' + (customerId || ''), done, fail),
		save: (data, done, fail = null) => this.post(':org/project/save', data, done, fail),
		delete: (id, done, fail = null) => this.get(':org/project/delete?id=' + id, done, fail),
		list: (options, done, fail = null) => this.post(':org/project/list', options || {}, done, fail),
		listCustomerProjects: (customerId, done, fail = null) => this.get(':org/project/list_customer_projects?id=' + customerId, done, fail),
		listActiveProjects: (done, fail = null) => this.get(':org/project/list_active_projects', done, fail),
		listInvoices: (options, done, fail = null) => this.post(':org/project/list_invoices', options || {}, done, fail),
		invoicing: (id, done, fail = null) => this.get(':org/project/invoicing?id=' + id, done, fail),
		invoicingQuoteInfo: (invoiceId, done, fail = null) => this.get(':org/project/invoicing_quote_info?id=' + invoiceId, done, fail),
		createInvoice: (projectId, done, fail = null) => this.get(':org/project/create_invoice?id=' + projectId, done, fail),
		getInvoice: (invoiceId, done, fail = null) => this.get(':org/project/get_invoice?id=' + invoiceId, done, fail),
		updateInvoice: (data, done, fail = null) => this.post(':org/project/update_invoice', data, done, fail),
		regenerateInvoiceNo: (id, done, fail = null) => this.get(':org/project/regenerate_invoice_no?id=' + id, done, fail),
		updateInvoiceLine: (data, done, fail = null) => this.post(':org/project/update_invoice_line', data, done, fail),
		deleteDraftInvoice: (id, done, fail = null) => this.get(':org/project/delete_draft_invoice?id=' + id, done, fail),
		changeInvoiceStatus: (id, status, done, fail = null) => this.get(':org/project/change_invoice_status?id=' + id + '&status=' + status, done, fail),
		addToInvoice: (data, done, fail = null) => this.post(':org/project/add_to_invoice', data, done, fail),
		changeInvoiceProductDisplay: (id, display, done, fail = null) => this.get(':org/project/change_invoice_product_display?id=' + id + '&display=' + display, done, fail),
		changeInvoiceLabourDisplay: (id, display, done, fail = null) => this.get(':org/project/change_invoice_labour_display?id=' + id + '&display=' + display, done, fail),
		addDeposit: (id, depositId, done, fail = null) => this.get(':org/project/add_deposit?id=' + id + '&deposit_id=' + depositId, done, fail),
		removeDeposit: (id, depositId, done, fail = null) => this.get(':org/project/remove_deposit?id=' + id + '&deposit_id=' + depositId, done, fail),
		invoiceSelections: (id, done, fail = null) => this.get(':org/project/invoice_selections?id=' + id, done, fail),
		unallocateInvoiceQuoteLine: (invoice_id, quote_line_id, done, fail = null) => this.post(':org/project/unallocate_invoice_quote_line', { invoice_id, quote_line_id }, done, fail),
		unallocateInvoiceQuoteLabour: (invoice_id, quote_labour_id, done, fail = null) => this.post(':org/project/unallocate_invoice_quote_labour', { invoice_id, quote_labour_id }, done, fail),
		removeInvoiceSelections: (data, done, fail = null) => this.post(':org/project/remove_invoice_selections', data, done, fail),
		addInvoicePayment: (data, done, fail = null) => this.post(':org/project/add_invoice_payment', data, done, fail),
		addQuotesToProject: (data, done, fail = null) => this.post(':org/project/add_quotes_to_project', data, done, fail),
		searchAccountsContacts: (data, done, fail = null) => this.post(':org/project/search_accounts_contacts', data, done, fail),
		linkAccountsContact: (data, done, fail = null) => this.post(':org/project/link_accounts_contact', data, done, fail),
		createAccountsContact: (customerId, done, fail = null) => this.get(':org/project/create_accounts_contact?id=' + customerId, done, fail),
		sendInvoiceToAccounts: (id, done, fail = null) => this.get(':org/project/send_invoice_to_accounts?id=' + id, done, fail),
		getPickList: (id, sort, done, fail = null) => this.get(':org/project/get_pick_list?id=' + id + '&sort=' + sort, done, fail),
		savePickListItem: (data, done, fail = null) => this.post(':org/project/save_pick_list', data, done, fail),
		invoicedItems: (invoiceId, done, fail = null) => this.get(':org/project/invoiced_items?id=' + invoiceId, done, fail),
		syncZoho: (done, fail = null) => this.get(':org/project/sync_zoho', done, fail),
		sendToZoho: (id, done, fail = null) => this.get(':org/project/send_to_zoho?id=' + id, done, fail),
		getPaymentStatements: (projectId, done, fail = null) => this.get(':org/project/get_payment_statements?project_id=' + projectId, done, fail),

		listAllPO: (data, done, fail = null) => this.post(':org/project/list_all_po', data, done, fail),
		listPO: (data, done, fail = null) => this.post(':org/project/list_po', data, done, fail),
		getPOLines: (id, done, fail = null) => this.get(':org/project/get_po_lines?id=' + id, done, fail),
		listQuotesForPO: (projectId, done, fail = null) => this.get(':org/project/list_quotes_for_po?project_id=' + projectId, done, fail),
		listQuoteLinesForPO: (filters, done, fail = null) => this.post(':org/project/list_quote_lines_for_po', filters, done, fail),
		addLinesToPO: (data, done, fail = null) => this.post(':org/project/add_lines_to_po', data, done, fail),
		addProductsToPO: (data, done, fail = null) => this.post(':org/project/add_products_to_po', data, done, fail),
		deletePO: (id, done, fail = null) => this.get(':org/project/delete_po?id=' + id, done, fail),
		deletePOLine: (id, done, fail = null) => this.get(':org/project/delete_po_line?id=' + id, done, fail),
		getPO: (id, done, fail = null) => this.get(':org/project/get_po?id=' + id, done, fail),
		createPO: (data, done, fail = null) => this.post(':org/project/create_po', data, done, fail),
		savePO: (data, done, fail = null) => this.post(':org/project/save_po', data, done, fail),
		getPOLine: (id, done, fail = null) => this.get(':org/project/get_po_line?id=' + id, done, fail),
		updatePOLine: (data, done, fail = null) => this.post(':org/project/update_po_line', data, done, fail),
		allocateToInvoice: (data, done, fail = null) => this.post(':org/project/allocate_to_invoice', data, done, fail),
		getPOSendOptions: (id, done, fail = null) => this.get(':org/project/get_po_send_options?id=' + id, done, fail),
		sendPOToSupplier: (data, done, fail = null) => this.post(':org/project/send_po_to_supplier', data, done, fail),
		updateReceivedQuantity: (data, done, fail = null) => this.post(':org/project/update_received_quantity', data, done, fail),
		markAllOrderQuantityReceived: (poId, done, fail = null) => this.get(':org/project/mark_all_order_quanity_received?poId=' + poId, done, fail),
		newCustomLine: (poId, done, fail = null) => this.get(':org/project/new_custom_line?poId=' + poId, done, fail),
		updateCustomLine: (data, done, fail = null) => this.post(':org/project/update_custom_line', data, done, fail),

		listLaboursForPO: (projectId, done, fail = null) => this.get(':org/project/list_labours_for_po?project_id=' + projectId, done, fail),
		listLabourLinesForPO: (filters, done, fail = null) => this.post(':org/project/list_labour_lines_for_po', filters, done, fail),
		addLabourLinesToPO: (data, done, fail = null) => this.post(':org/project/add_labour_lines_to_po', data, done, fail),
		getPOLabourLine: (id, done, fail = null) => this.get(':org/project/get_po_labour_line?id=' + id, done, fail),
		updatePOLabourLine: (data, done, fail = null) => this.post(':org/project/update_po_labour_line', data, done, fail),

		createSnag: (data, done, fail = null) => this.post(':org/project/create_snag', data, done, fail),
		getSnag: (snagId, done, fail = null) => this.get(':org/project/get_snag?id=' + snagId, done, fail),
		updateSnag: (data, done, fail = null) => this.post(':org/project/update_snag', data, done, fail),
		deleteSnag: (id, done, fail = null) => this.get(':org/project/delete_snag?id=' + id, done, fail),
		listSnags: (id, done, fail = null) => this.get(':org/project/list_snags?id=' + id, done, fail),

		listTimesheets: (filters, done, fail = null) => this.get(':org/project/list_timesheets?' + this.objectToQueryString(filters), done, fail),
		newTimesheet: (projectId, done, fail = null) => this.get(':org/project/new_timesheet?project_id=' + projectId, done, fail),
		getTimesheet: (id, done, fail = null) => this.get(':org/project/get_timesheet?id=' + id, done, fail),
		saveTimesheet: (data, done, fail = null) => this.post(':org/project/save_timesheet', data, done, fail),
		archiveTimesheet: (id, done, fail = null) => this.get(':org/project/archive_timesheet?id=' + id, done, fail),
		unarchiveTimesheet: (id, done, fail = null) => this.get(':org/project/unarchive_timesheet?id=' + id, done, fail),
		getWeekwiseTimesheetInsights: (done, fail = null) => this.get(':org/project/get_weekwise_timesheet_insights', done, fail),

		getSystemwiseLabour: (projectId, done, fail = null) => this.get(':org/project/get_systemwise_labour?project_id=' + projectId, done, fail),
		getLabourwiseSystem: (projectId, done, fail = null) => this.get(':org/project/get_labourwise_system?project_id=' + projectId, done, fail),
		getTimesheetTotals: (projectId, done, fail = null) => this.get(':org/project/get_timesheet_totals?project_id=' + projectId, done, fail),
		getUserwiseLabour: (projectId, done, fail = null) => this.get(':org/project/get_userwise_labour?project_id=' + projectId, done, fail),
		getActiveWorkingUsers: (done, fail = null) => this.get(':org/project/get_active_working_users', done, fail),

		listTaskTimeEntries: (filters, done, fail = null) => this.get(':org/project/list_task_time_entries?' + this.objectToQueryString(filters), done, fail),
		getUserwiseTaskEntries: (projectId, done, fail = null) => this.get(':org/project/get_userwise_task_entries?project_id=' + projectId, done, fail),
		getTaskEntriesTotals: (projectId, done, fail = null) => this.get(':org/project/get_task_entries_totals?project_id=' + projectId, done, fail),
		getWorkOrderwiseTimeData: (projectId, done, fail = null) => this.get(':org/project/get_workorderwise_timedata?project_id=' + projectId, done, fail),
		getTaskwiseTimeData: (projectId, done, fail = null) => this.get(':org/project/get_taskwise_timedata?project_id=' + projectId, done, fail),

		changeStatus: (id, status, done, fail = null) => this.get(':org/project/change_status?id=' + id + '&status=' + status, done, fail),
		getProjectActivities: (id, done, fail = null) => this.get(':org/project/get_project_activities?id=' + id, done, fail),
	};

	customer = {
		list: (options, done, fail = null) => this.post(':org/customer/list', options || {}, done, fail),
		details: (id, done, fail = null) => this.get(':org/customer/details?id=' + id, done, fail),
		new: (done, fail = null) => this.get(':org/customer/new', done, fail),
		save: (data, done, fail = null) => this.post(':org/customer/save', data, done, fail),
		addContact: (data, done, fail = null) => this.post(':org/customer/add_contact', data, done, fail),
		delete: (id, done, fail = null) => this.get(':org/customer/delete?id=' + id, done, fail),
		syncZoho: (done, fail = null) => this.get(':org/customer/sync_zoho', done, fail),
		syncMagento: (done, fail = null) => this.get(':org/customer/sync_magento', done, fail),
		syncQuickBooks: (done, fail = null) => this.get(':org/customer/sync_quickbooks', done, fail),
		
		overview: (data, done, fail = null) => this.post(':org/customer/overview', data, done, fail),
		fetchSiteVisits: (options, done, fail = null) => this.post(':org/customer/fetch_site_visits', options || {}, done, fail),
	};

	assets = {
		list: (options, done, fail = null) => this.post(':org/assets/list', options || {}, done, fail),
		get: (id, done, fail = null) => this.get(':org/assets/get?id='+ id, done, fail),
		create: (data, done, fail = null) => this.post(':org/assets/create', data, done, fail),
		update: (data, done, fail = null) => this.post(':org/assets/update', data, done, fail),
		delete: (data, done, fail = null) => this.post(':org/assets/delete', data, done, fail)
	};

	product = {
		list: (options, done, fail = null) => this.post(':org/product/list', options, done, fail),
		get: (id, done, fail = null) => this.get(':org/product/get?id=' + id, done, fail),
		new: (options, done, fail = null) => this.post(':org/product/new', options || {}, done, fail),
		save: (data, done, fail = null) => this.post(':org/product/save', data, done, fail),
		clone: (data, done, fail = null) => this.post(':org/product/clone', data, done, fail),
		bomProduct: (id, productId, done, fail = null) => this.get(':org/product/bom_product?id=' + id + '&product_id=' + productId, done, fail),

		listLabourTypes: (done, fail = null) => this.get(':org/product/list_labour_types', done, fail),
		getLabourCategory: (id, done, fail = null) => this.get(':org/product/get_labour_category?id=' + id, done, fail),
		newLabourCategory: (done, fail = null) => this.get(':org/product/new_labour_category', done, fail),
		saveLabourCategory: (data, done, fail = null) => this.post(':org/product/save_labour_category', data, done, fail),
		deleteLabourCategory: (id, done, fail = null) => this.get(':org/product/delete_labour_category?id=' + id, done, fail),
		archiveLabourCategory: (id, done, fail = null) => this.get(':org/product/archive_labour_category?id=' + id, done, fail),
		unarchiveLabourCategory: (id, done, fail = null) => this.get(':org/product/unarchive_labour_category?id=' + id, done, fail),
		getLabourType: (id, done, fail = null) => this.get(':org/product/get_labour_type?id=' + id, done, fail),
		newLabourType: (done, fail = null) => this.get(':org/product/new_labour_type', done, fail),
		saveLabourType: (data, done, fail = null) => this.post(':org/product/save_labour_type', data, done, fail),
		deleteLabourType: (id, done, fail = null) => this.get(':org/product/delete_labour_type?id=' + id, done, fail),
		archiveLabourType: (id, done, fail = null) => this.get(':org/product/archive_labour_type?id=' + id, done, fail),
		unarchiveLabourType: (id, done, fail = null) => this.get(':org/product/unarchive_labour_type?id=' + id, done, fail),

		listSubscriptionTypes: (done, fail = null) => this.get(':org/product/list_subscription_types', done, fail),
		getSubscriptionType: (id, done, fail = null) => this.get(':org/product/get_subscription_type?id=' + id, done, fail),
		getSubscriptionCategory: (id, done, fail = null) => this.get(':org/product/get_subscription_category?id=' + id, done, fail),
		newSubscriptionCategory: (done, fail = null) => this.get(':org/product/new_subscription_category', done, fail),
		newSubscriptionType: (done, fail = null) => this.get(':org/product/new_subscription_type', done, fail),
		saveSubscriptionType: (data, done, fail = null) => this.post(':org/product/save_subscription_type', data, done, fail),
		saveSubscriptionCategory: (data, done, fail = null) => this.post(':org/product/save_subscription_category', data, done, fail),
		deleteSubscriptionType: (id, done, fail = null) => this.get(':org/product/delete_subscription_type?id=' + id, done, fail),
		deleteSubscriptionCategory: (id, done, fail = null) => this.get(':org/product/delete_subscription_category?id=' + id, done, fail),

		listPricingStructures: (done, fail = null) => this.get(':org/product/list_pricing_structures', done, fail),
		getPricingStructure: (id, done, fail = null) => this.get(':org/product/get_pricing_structure?id=' + id, done, fail),
		newPricingStructure: (done, fail = null) => this.get(':org/product/new_pricing_structure', done, fail),
		savePricingStructure: (data, done, fail = null) => this.post(':org/product/save_pricing_structure', data, done, fail),
		deletePricingStructure: (id, done, fail = null) => this.get(':org/product/delete_pricing_structure?id=' + id, done, fail),

		listBaseUnits: (done, fail = null) => this.get(':org/product/list_base_units', done, fail),
		getBaseUnit: (id, done, fail = null) => this.get(':org/product/get_base_unit?id=' + id, done, fail),
		newBaseUnit: (done, fail = null) => this.get(':org/product/new_base_unit', done, fail),
		saveBaseUnit: (data, done, fail = null) => this.post(':org/product/save_base_unit', data, done, fail),
		setDefaultUnit: (unitId, done, fail = null) => this.get(':org/product/set_default_unit?unit_id=' + (unitId || ''), done, fail),

		listProductTypes: (done, fail = null) => this.get(':org/product/list_product_types', done, fail),
		getProductType: (id, done, fail = null) => this.get(':org/product/get_product_type?id=' + id, done, fail),
		newProductType: (done, fail = null) => this.get(':org/product/new_product_type', done, fail),
		saveProductType: (data, done, fail = null) => this.post(':org/product/save_product_type', data, done, fail),
		deleteProductType: (id, done, fail = null) => this.get(':org/product/delete_product_type?id=' + id, done, fail),

		addToToolkit: (data, done, fail = null) => this.post(':org/product/add_to_toolkit', data, done, fail),
		isSingle: (productId, done, fail = null) => this.get(':org/product/is_single?id=' + productId, done, fail),

		listProductCategories: (done, fail = null) => this.get(':org/product/list_product_categories', done, fail),
		getProductCategory: (id, done, fail = null) => this.get(':org/product/get_product_category?id=' + id, done, fail),
		newProductCategory: (groupId, done, fail = null) => this.get(':org/product/new_product_category?group_id=' + (groupId || ''), done, fail),
		saveProductCategory: (data, done, fail = null) => this.post(':org/product/save_product_category', data, done, fail),
		canDeleteProductCategory: (id, done, fail = null) => this.get(':org/product/can_delete_product_category?id=' + id, done, fail),
		deleteProductCategory: (id, done, fail = null) => this.get(':org/product/delete_product_category?id=' + id, done, fail),
		archiveProductCategory: (id, done, fail = null) => this.get(':org/product/archive_product_category?id=' + id, done, fail),
		unarchiveProductCategory: (id, done, fail = null) => this.get(':org/product/unarchive_product_category?id=' + id, done, fail),
		getProductCategoryGroup: (id, done, fail = null) => this.get(':org/product/get_product_category_group?id=' + id, done, fail),
		newProductCategoryGroup: (done, fail = null) => this.get(':org/product/new_product_category_group', done, fail),
		saveProductCategoryGroup: (data, done, fail = null) => this.post(':org/product/save_product_category_group', data, done, fail),
		canDeleteProductCategoryGroup: (id, done, fail = null) => this.get(':org/product/can_delete_product_category_group?id=' + id, done, fail),
		deleteProductCategoryGroup: (id, done, fail = null) => this.get(':org/product/delete_product_category_group?id=' + id, done, fail),
		archiveProductCategoryGroup: (id, done, fail = null) => this.get(':org/product/archive_product_category_group?id=' + id, done, fail),
		unarchiveProductCategoryGroup: (id, done, fail = null) => this.get(':org/product/unarchive_product_category_group?id=' + id, done, fail),
		saveProductCategoryGroupOrder: (data, done, fail = null) => this.post(':org/product/save_product_category_group_order', data, done, fail),

		listProductCounters: (done, fail = null) => this.get(':org/product/list_product_counters', done, fail),
		getProductCounter: (id, done, fail = null) => this.get(':org/product/get_product_counter?id=' + id, done, fail),
		newProductCounter: (groupId, done, fail = null) => this.get(':org/product/new_product_counter?group_id=' + (groupId || ''), done, fail),
		saveProductCounter: (data, done, fail = null) => this.post(':org/product/save_product_counter', data, done, fail),
		deleteProductCounter: (id, done, fail = null) => this.get(':org/product/delete_product_counter?id=' + id, done, fail),
		getProductCounterGroup: (id, done, fail = null) => this.get(':org/product/get_product_counter_group?id=' + id, done, fail),
		newProductCounterGroup: (done, fail = null) => this.get(':org/product/new_product_counter_group', done, fail),
		saveProductCounterGroup: (data, done, fail = null) => this.post(':org/product/save_product_counter_group', data, done, fail),
		deleteProductCounterGroup: (id, done, fail = null) => this.get(':org/product/delete_product_counter_group?id=' + id, done, fail)
	};

	entity = {
		details: (id, done, fail = null) => this.get(':org/entity/details?id=' + id, done, fail),
		new: (done, fail = null) => this.get(':org/entity/new', done, fail),
		save: (data, done, fail = null) => this.post(':org/entity/save', data, done, fail),
		archive: (id, done, fail = null) => this.get(':org/entity/archive?id=' + id, done, fail),
		unarchive: (id, done, fail = null) => this.get(':org/entity/unarchive?id=' + id, done, fail),
		list: (filters, done, fail = null) => this.post(':org/entity/list', filters, done, fail)
	};

	stock = {
		listWarehouses: (done, fail = null) => this.get(':org/stock/list_warehouses', done, fail),
		getWarehouse: (id, done, fail = null) => this.get(':org/stock/get_warehouse?id=' + id, done, fail),
		newWarehouse: (done, fail = null) => this.get(':org/stock/new_warehouse', done, fail),
		saveWarehouse: (data, done, fail = null) => this.post(':org/stock/save_warehouse', data, done, fail),
		deleteWarehouse: (id, done, fail = null) => this.get(':org/stock/delete_warehouse?id=' + id, done, fail),

		listStockLocations: (id, done, fail = null) => this.get(':org/stock/list_stock_locations?id=' + id, done, fail),
		createStockLocation: (data, done, fail = null) => this.post(':org/stock/create_stock_location', data, done, fail),
		deleteStockLocation: (id, done, fail = null) => this.get(':org/stock/delete_stock_location?id=' + id, done, fail),
		setLocationLabel: (warehouse_id, rack, bay, level, label, done, fail = null) => this.post(':org/stock/set_location_label', { warehouse_id, rack, bay, level, label }, done, fail),

		getStockProductInfo: (product_id, warehouse_id, done, fail = null) => this.get(':org/stock/get_stock_product_info?id=' + product_id + '&warehouse=' + warehouse_id, done, fail),
		submitGoodsIn: (data, done, fail = null) => this.post(':org/stock/submit_goods_in', data, done, fail),
		submitGoodsOut: (data, done, fail = null) => this.post(':org/stock/submit_goods_out', data, done, fail),
		listStockByProduct: (warehouse_id, done, fail = null) => this.get(':org/stock/list_stock_by_product?warehouse_id=' + warehouse_id, done, fail),
		listStockByLocation: (warehouse_id, done, fail = null) => this.get(':org/stock/list_stock_by_location?warehouse_id=' + warehouse_id, done, fail),
		listTransactions: (warehouse_id, done, fail = null) => this.get(':org/stock/list_transactions?warehouse_id=' + warehouse_id, done, fail),
		listTransactionItems: (options, done, fail = null) => this.get(':org/stock/list_transaction_items?' + this.objectToQueryString(options), done, fail)
	};

	template = {
		list: (done, fail = null) => this.get(':org/template/list', done, fail),
		get: (id, done, fail = null) => this.get(':org/template/get?id=' + id, done, fail),
		new: (done, fail = null) => this.get(':org/template/new', done, fail),
		save: (data, done, fail = null) => this.post(':org/template/save', data, done, fail),
		clone: (id, done, fail = null) => this.get(':org/template/clone?id=' + id, done, fail),
		copyToMarketplace: (id, done, fail = null) => this.get(':org/template/copy_to_marketplace?id=' + id, done, fail),
		listFromMarketplace: (done, fail = null) => this.get(':org/template/list_from_marketplace', done, fail),
		copyFromMarketplace: (id, done, fail = null) => this.get(':org/template/copy_from_marketplace?id=' + id, done, fail),
		delete: (id, done, fail = null) => this.get(':org/template/delete?id=' + id, done, fail),

		listCovers: (done, fail = null) => this.get(':org/template/list_covers', done, fail),
		getCover: (id, done, fail = null) => this.get(':org/template/get_cover?id=' + id, done, fail),
		newCover: (done, fail = null) => this.get(':org/template/new_cover', done, fail),
		saveCover: (data, done, fail = null) => this.post(':org/template/save_cover', data, done, fail),
		cloneCover: (id, done, fail = null) => this.get(':org/template/clone_cover?id=' + id, done, fail),
		copyCoverToMarketplace: (id, done, fail = null) => this.get(':org/template/copy_cover_to_marketplace?id=' + id, done, fail),
		listCoversFromMarketplace: (done, fail = null) => this.get(':org/template/list_covers_from_marketplace', done, fail),
		copyCoverFromMarketplace: (id, done, fail = null) => this.get(':org/template/copy_cover_from_marketplace?id=' + id, done, fail),
		deleteCover: (id, done, fail = null) => this.get(':org/template/delete_cover?id=' + id, done, fail),

		listHeaders: (done, fail = null) => this.get(':org/template/list_headers', done, fail),
		getHeader: (id, done, fail = null) => this.get(':org/template/get_header?id=' + id, done, fail),
		newHeader: (done, fail = null) => this.get(':org/template/new_header', done, fail),
		saveHeader: (data, done, fail = null) => this.post(':org/template/save_header', data, done, fail),
		copyHeaderToMarketplace: (id, done, fail = null) => this.get(':org/template/copy_header_to_marketplace?id=' + id, done, fail),
		listHeadersFromMarketplace: (done, fail = null) => this.get(':org/template/list_headers_from_marketplace', done, fail),
		copyHeaderFromMarketplace: (id, done, fail = null) => this.get(':org/template/copy_header_from_marketplace?id=' + id, done, fail),
		deleteHeader: (id, done, fail = null) => this.get(':org/template/delete_header?id=' + id, done, fail),

		listThemes: (done, fail = null) => this.get(':org/template/list_themes', done, fail),
		getTheme: (id, done, fail = null) => this.get(':org/template/get_theme?id=' + id, done, fail),
		newTheme: (done, fail = null) => this.get(':org/template/new_theme', done, fail),
		saveTheme: (data, done, fail = null) => this.post(':org/template/save_theme', data, done, fail),
		cloneTheme: (id, done, fail = null) => this.get(':org/template/clone_theme?id=' + id, done, fail),
		deleteTheme: (id, done, fail = null) => this.get(':org/template/delete_theme?id=' + id, done, fail),

		getLogoUrl: (done, fail = null) => this.get(':org/template/get_logo_url', done, fail),
		getIconUrl: (done, fail = null) => this.get(':org/template/get_icon_url', done, fail)
	};

	category = {
		list: (done, fail = null) => this.get(':org/category/list', done, fail),
		marketplaceList: (done, fail = null) => this.get('marketplace/category/list', done, fail),
		get: (id, quoteId, done, fail = null) => this.get(':org/category/get?id=' + id + '&quote_id=' + (quoteId || ''), done, fail),
		new: (groupId, done, fail = null) => this.get(':org/category/new?group_id=' + (groupId || ''), done, fail),
		save: (data, done, fail = null) => this.post(':org/category/save', data, done, fail),
		canDelete: (id, done, fail = null) => this.get(':org/category/can_delete?id=' + id, done, fail),
		delete: (id, done, fail = null) => this.get(':org/category/delete?id=' + id, done, fail),
		archive: (id, done, fail = null) => this.get(':org/category/archive?id=' + id, done, fail),
		unarchive: (id, done, fail = null) => this.get(':org/category/unarchive?id=' + id, done, fail),
		getGroup: (id, quoteId, done, fail = null) => this.get(':org/category/get_group?id=' + id + '&quote_id=' + (quoteId || ''), done, fail),
		newGroup: (done, fail = null) => this.get(':org/category/new_group', done, fail),
		saveGroup: (data, done, fail = null) => this.post(':org/category/save_group', data, done, fail),
		canDeleteGroup: (id, done, fail = null) => this.get(':org/category/can_delete_group?id=' + id, done, fail),
		deleteGroup: (id, done, fail = null) => this.get(':org/category/delete_group?id=' + id, done, fail),
		archiveGroup: (id, done, fail = null) => this.get(':org/category/archive_group?id=' + id, done, fail),
		unarchiveGroup: (id, done, fail = null) => this.get(':org/category/unarchive_group?id=' + id, done, fail),
		listPresets: (done, fail = null) => this.get(':org/category/list_presets', done, fail),
		preset: (id, done, fail = null) => this.get(':org/category/preset?id=' + id, done, fail),
		addFromPreset: (data, done, fail = null) => this.post(':org/category/add_from_preset', data, done, fail),
		marketplaceCopyInfo: (direction, object, id, done, fail = null) => this.post(':org/category/marketplace_copy_info', { direction, object, id }, done, fail),
		marketplaceCopy: (direction, object, id, done, fail = null) => this.post(':org/category/marketplace_copy', { direction, object, id }, done, fail),
		saveGroupOrder: (data, done, fail = null) => this.post(':org/category/save_group_order', data, done, fail)
	};

	content = {
		listUserContent: (type, done, fail = null) => this.get(':org/content/list_user_content?type=' + type, done, fail),
		uploadUserContent: (formData, type, done, fail = null) => this.post(':org/content/upload_user_content?type=' + type, formData, done, fail),
		uploadUserContentURL: (url, type, done, fail = null) => this.post(':org/content/upload_user_content_url?type=' + type, { url }, done, fail),
		archiveImages: (list, done, fail = null) => this.post(':org/content/archive_images', list, done, fail),
		printSnaggReport: (data, done, fail = null) => this.post(':org/content/print_snagg_report_pdf', data, done, fail)
	};

	settings = {
		profile: (done, fail = null) => this.get(':org/settings/profile', done, fail),
		saveProfile: (data, done, fail = null) => this.post(':org/settings/save_profile', data, done, fail),
		organisationProfile: (done, fail = null) => this.get(':org/settings/organisation_profile', done, fail),
		saveOrganisationProfile: (data, done, fail = null) => this.post(':org/settings/save_organisation_profile', data, done, fail),
		users: (done, fail = null) => this.get(':org/settings/users', done, fail),
		setUserRole: (data, done, fail = null) => this.post(':org/settings/set_user_role', data, done, fail),
		deleteUser: (data, done, fail = null) => this.post(':org/settings/delete_user', data, done, fail),
		sendInvite: (data, done, fail = null) => this.post(':org/settings/send_invite', data, done, fail),
		resendInvite: (id, done, fail = null) => this.get(':org/settings/resend_invite?id=' + id, done, fail),
		cancelInvite: (id, done, fail = null) => this.get(':org/settings/cancel_invite?id=' + id, done, fail),

		newUserRole: (done, fail = null) => this.get(':org/settings/new_user_role', done, fail),
		getUserRole: (id, done, fail = null) => this.get(':org/settings/get_user_role?id=' + id, done, fail),
		saveUserRole: (data, done, fail = null) => this.post(':org/settings/save_user_role', data, done, fail),
		deleteUserRole: (id, done, fail = null) => this.get(':org/settings/delete_user_role?id=' + id, done, fail),

		createAccount: (data, done, fail = null) => this.post(':org/settings/create_account', data, done, fail),
		billingInformation: (done, fail = null) => this.get(':org/settings/billing_information', done, fail),
		allBillingInformation: (options, done, fail = null) => this.post(':org/settings/all_billing_information', options, done, fail),
		stuckSignups: (done, fail = null) => this.get(':org/settings/stuck_signups', done, fail),
		resendVerificationCode: (id, done, fail = null) => this.get(':org/settings/resend_verification_code?id=' + id, done, fail),
		getDefaultPaymentInformation: (done, fail = null) => this.get(':org/settings/get_default_payment_information', done, fail),
		saveDefaultPaymentInformation: (data, done, fail = null) => this.post(':org/settings/save_default_payment_information', data, done, fail),

		listAliases: (done, fail = null) => this.get(':org/settings/list_aliases', done, fail),
		newAlias: (done, fail = null) => this.get(':org/settings/new_alias', done, fail),
		getAlias: (id, done, fail = null) => this.get(':org/settings/get_alias?id=' + id, done, fail),
		saveAlias: (data, done, fail = null) => this.post(':org/settings/save_alias', data, done, fail),
		deleteAlias: (id, done, fail = null) => this.get(':org/settings/delete_alias?id=' + id, done, fail),

		integrations: (done, fail = null) => this.get(':org/settings/integrations', done, fail),
		integrationSnaggRemoveUser: (id, done, fail = null) => this.get(':org/settings/integration_snagg_remove_user?id=' + id, done, fail),
		integrationZohoTest: (done, fail = null) => this.get(':org/settings/integration_zoho_test', done, fail),
		integrationZohoRemove: (done, fail = null) => this.get(':org/settings/integration_zoho_remove', done, fail),
		integrationGetZohoSetup: (done, fail = null) => this.get(':org/settings/integration_get_zoho_setup', done, fail),
		integrationSaveZohoSetup: (data, done, fail = null) => this.post(':org/settings/integration_save_zoho_setup', data, done, fail),
		integrationZohoBooksTest: (done, fail = null) => this.get(':org/settings/integration_zoho_books_test', done, fail),
		integrationZohoBooksRemove: (done, fail = null) => this.get(':org/settings/integration_zoho_books_remove', done, fail),
		integrationListZohoBooksItems: (data, done, fail = null) => this.post(':org/settings/integration_list_zoho_books_items', data, done, fail),
		integrationGetZohoBooksSetup: (done, fail = null) => this.get(':org/settings/integration_get_zoho_books_setup', done, fail),
		integrationSaveZohoBooksSetup: (data, done, fail = null) => this.post(':org/settings/integration_save_zoho_books_setup', data, done, fail),
		integrationXeroTest: (done, fail = null) => this.get(':org/settings/integration_xero_test', done, fail),
		integrationXeroRemove: (done, fail = null) => this.get(':org/settings/integration_xero_remove', done, fail),
		integrationGetXeroSetup: (done, fail = null) => this.get(':org/settings/integration_get_xero_setup', done, fail),
		integrationSaveXeroSetup: (data, done, fail = null) => this.post(':org/settings/integration_save_xero_setup', data, done, fail),
		integrationQuickBooksTest: (done, fail = null) => this.get(':org/settings/integration_quickbooks_test', done, fail),
		integrationQuickBooksRemove: (done, fail = null) => this.get(':org/settings/integration_quickbooks_remove', done, fail),
		integrationSearchQuickBooksItems: (data, done, fail = null) => this.post(':org/settings/integration_search_quickbooks_items', data, done, fail),
		integrationGetQuickBooksSetup: (done, fail = null) => this.get(':org/settings/integration_get_quickbooks_setup', done, fail),
		integrationSaveQuickBooksSetup: (data, done, fail = null) => this.post(':org/settings/integration_save_quickbooks_setup', data, done, fail),
		integrationStripeTest: (done, fail = null) => this.get(':org/settings/integration_stripe_test', done, fail),
		integrationStripeRemove: (done, fail = null) => this.get(':org/settings/integration_stripe_remove', done, fail),

		listNumberFormats: (done, fail = null) => this.get(':org/settings/list_number_formats', done, fail),
		getNumberFormat: (id, done, fail = null) => this.get(':org/settings/get_number_format?id=' + id, done, fail),
		saveNumberFormat: (data, done, fail = null) => this.post(':org/settings/save_number_format', data, done, fail),

		listTaxes: (done, fail = null) => this.get(':org/settings/list_taxes', done, fail),
		newTax: (done, fail = null) => this.get(':org/settings/new_tax', done, fail),
		getTax: (id, done, fail = null) => this.get(':org/settings/get_tax?id=' + id, done, fail),
		saveTax: (data, done, fail = null) => this.post(':org/settings/save_tax', data, done, fail),
		setDefaultTaxRate: (taxId, done, fail = null) => this.get(':org/settings/set_default_tax_rate?tax_id=' + (taxId || ''), done, fail),

		getTranslatedTerm: (term, done, fail = null) => this.get(':org/settings/get_translated_term?term=' + term, done, fail),
		saveTranslatedTerm: (data, done, fail = null) => this.post(':org/settings/save_translated_term', data, done, fail),
		newTranslatedTerm: (term, done, fail = null) => this.get(':org/settings/new_translated_term?term=' + term, done, fail),

		listLabels: (options, done, fail = null) => this.post(':org/settings/list_labels', options || {}, done, fail),
		newLabel: (done, fail = null) => this.get(':org/settings/new_label', done, fail),
		getLabel: (id, done, fail = null) => this.get(':org/settings/get_label?id=' + id, done, fail),
		saveLabel: (data, done, fail = null) => this.post(':org/settings/save_label', data, done, fail),
		archiveLabel: (id, done, fail = null) => this.get(':org/settings/archive_label?id=' + id, done, fail),
		unarchiveLabel: (id, done, fail = null) => this.get(':org/settings/unarchive_label?id=' + id, done, fail),

		getJobTitles: (done, fail = null) => this.get(':org/settings/get_job_titles', done, fail),
		addJobtitle: (data, done, fail = null) => this.post(':org/settings/add_job_title', data, done, fail)
	};

	catalogue = {
		list: (data, done, fail = null) => this.post(':org/catalogue/list', data, done, fail),
		listApprovedCatalogues: (done, fail = null) => this.get(':org/catalogue/list_approved_catalogues', done, fail),
		listMyCatalogues: (done, fail = null) => this.get(':org/catalogue/list_my_catalogues', done, fail),
		create: (done, fail = null) => this.get(':org/catalogue/create', done, fail),
		delete: (id, done, fail = null) => this.get(':org/catalogue/delete?id=' + id, done, fail),
		get: (id, done, fail = null) => this.get(':org/catalogue/get?id=' + id, done, fail),
		update: (record, done, fail = null) => this.post(':org/catalogue/update', record, done, fail),
		removeProductFromCatalogue: (productId, catalogueId, done, fail = null) => this.get(':org/catalogue/remove_product_from_catalogue?id=' + productId + '&catalogue_id=' + catalogueId, done, fail),
		addProductsNotInCatalogue: (id, done, fail = null) => this.get(':org/catalogue/add_products_not_in_catalogue?id=' + id, done, fail),
		addProductsToCatalogue: (data, done, fail = null) => this.post(':org/catalogue/add_products_to_catalogue', data, done, fail),
		listCatalogueProducts: (data, done, fail = null) => this.post(':org/catalogue/list_catalogue_products', data, done, fail),
		getFullInfo: (data, done, fail = null) => this.post(':org/catalogue/get_full_info', data, done, fail),
		getSubscription: (catalogueId, done, fail = null) => this.get(':org/catalogue/get_subscription?id=' + catalogueId, done, fail),
		updateSubscription: (data, done, fail = null) => this.post(':org/catalogue/update_subscription', data, done, fail),
		subscriptionFill: (done, fail = null) => this.get(':org/catalogue/subscription_fill', done, fail),
		deleteSubscription: (catalogueId, done, fail = null) => this.get(':org/catalogue/delete_subscription?id=' + catalogueId, done, fail),
		listOwnerSubscription: (filters, done, fail = null) => this.post(':org/catalogue/list_owner_subscription', filters, done, fail),
		getOwnerSubscription: (organisationId, catalogueId, done, fail = null) => this.get(':org/catalogue/get_owner_subscription?id=' + catalogueId + '&oid=' + organisationId, done, fail),
		approveOwnerSubscription: (data, done, fail = null) => this.post(':org/catalogue/approve_owner_subscription', data, done, fail),
		cancelOwnerSubscription: (data, done, fail = null) => this.post(':org/catalogue/cancel_owner_subscription', data, done, fail),
		updateOwnerSubscription: (data, done, fail = null) => this.post(':org/catalogue/update_owner_subscription', data, done, fail),
		listAllSubscriptions: (filter, done, fail = null) => this.post(':org/catalogue/list_all_subscriptions', filter, done, fail),
		discounts: (customerId, catalogueId, done, fail = null) => this.get(':org/catalogue/discounts?customer_id=' + customerId + '&catalogue_id=' + catalogueId, done, fail),
		saveDiscounts: (data, done, fail = null) => this.post(':org/catalogue/save_discounts', data, done, fail),
		pricing: (organisationId, catalogueId, done, fail = null) => this.get(':org/catalogue/pricing?organisation_id=' + organisationId + '&catalogue_id=' + catalogueId, done, fail),
		savePricing: (data, done, fail = null) => this.post(':org/catalogue/save_pricing', data, done, fail),
		getImportExport: (catalogueId, done, fail = null) => this.get(':org/catalogue/get_import_export?id=' + catalogueId, done, fail),
		requestExport: (catalogueId, done, fail = null) => this.get(':org/catalogue/request_export?id=' + catalogueId, done, fail),
		requestImport: (data, done, fail = null) => this.post(':org/catalogue/request_import', data, done, fail),
		discountMatrix: (done, fail = null) => this.get(':org/catalogue/discount_matrix', done, fail),
		getDiscountImportExport: (done, fail = null) => this.get(':org/catalogue/get_discount_import_export', done, fail),
		requestDiscountExport: (done, fail = null) => this.get(':org/catalogue/request_discount_export', done, fail),
		requestDiscountImport: (data, done, fail = null) => this.post(':org/catalogue/request_discount_import', data, done, fail)
	};

	alert = {
		list: (done, fail = null) => this.get(':org/alert/list', done, fail),
		listRecent: (done, fail = null) => this.get(':org/alert/list_recent', done, fail),
		listByLocation: (location_id, done, fail = null) => this.get(':org/alert/list_by_location?location_id=' + location_id, done, fail),
		count: (done, fail = null) => this.get(':org/alert/count', done, fail),
		dismiss: (alertId, done, fail = null) => this.get(':org/alert/dismiss?id=' + alertId, done, fail),
		dismissAll: (done, fail = null) => this.get(':org/alert/dismiss_all', done, fail),
		dismissAllByLocation: (location_id, done, fail = null) => this.get(':org/alert/dismiss_all_by_location?location_id=' + location_id, done, fail),
		details: (alertId, done, fail = null) => this.get(':org/alert/details?id=' + alertId, done, fail),
		new: (done, fail = null) => this.get(':org/alert/new', done, fail),
		get: (alertId, done, fail = null) => this.get(':org/alert/get?id=' + alertId, done, fail),
		save: (data, done, fail = null) => this.post(':org/alert/save', data, done, fail),
		delete: (alertId, done, fail = null) => this.get(':org/alert/delete?id=' + alertId, done, fail)
	};

	activity = {
		get: (source, type, done, fail = null) => this.get(':org/activity/get?source=' + source + '&type=' + type, done, fail),
		count: (source, type, done, fail = null) => this.get(':org/activity/count?source=' + source + '&type=' + type, done, fail),
		create: (data, done, fail = null) => this.post(':org/activity/create', data, done, fail),
		update: (data, done, fail = null) => this.post(':org/activity/update', data, done, fail),
		delete: (data, done, fail = null) => this.post(':org/activity/delete', data, done, fail)
	};

	import = {
		importByFile: (data, done, fail = null) => this.post(':org/import/import_by_file', data, done, fail),
		importByURL: (data, done, fail = null) => this.post(':org/import/import_by_url', data, done, fail),
		getQuoteCurrencyRates: (id, done, fail = null) => this.get(':org/import/get_quote_currency_rates?id=' + id, done, fail)
	};

	currency = {
		details: (done, fail = null) => this.get(':org/currency/details', done, fail),
		add: (currency, done, fail = null) => this.get(':org/currency/add?currency=' + currency, done, fail),
		remove: (currency, done, fail = null) => this.get(':org/currency/remove?currency=' + currency, done, fail),
		setPrimary: (currency, done, fail = null) => this.get(':org/currency/set_primary?currency=' + currency, done, fail),
		addConversionRate: (data, done, fail = null) => this.post(':org/currency/add_conversion_rate', data, done, fail),
		removeConversionRate: (data, done, fail = null) => this.post(':org/currency/remove_conversion_rate', data, done, fail)
	};

	supplier = {
		getTotals: (data, done, fail = null) => this.post(':org/supplier/get_totals', data, done, fail),
		getSupplierSummary: (data, done, fail = null) => this.post(':org/supplier/get_supplier_summary', data, done, fail),
		listDealerQuotes: (data, done, fail = null) => this.post(':org/supplier/list_dealer_quotes', data, done, fail),
		listBrands: (data, done, fail = null) => this.post(':org/supplier/list_brands', data, done, fail),
		listProducts: (data, done, fail = null) => this.post(':org/supplier/list_products', data, done, fail),
		quoteInfo: (id, done, fail = null) => this.get(':org/supplier/quote_info?id=' + id, done, fail)
	};

	sales = {
		targets: (data, done, fail = null) => this.post(':org/sales/targets', data, done, fail),
		listNonSalesUsers: (done, fail = null) => this.get(':org/sales/list_non_sales_users', done, fail),
		addSalesPerson: (id, done, fail = null) => this.get(':org/sales/add_sales_person?id=' + id, done, fail),
		updateTargets: (data, done, fail = null) => this.post(':org/sales/update_targets', data, done, fail),
		overview: (data, done, fail = null) => this.post(':org/sales/overview', data, done, fail),
		getSalesUser: (id, done, fail = null) => this.get(':org/sales/get_sales_user?id=' + id, done, fail),
		saveSalesUser: (data, done, fail = null) => this.post(':org/sales/save_sales_user', data, done, fail)
	};

	team = {
		listTeams: (done, fail = null) => this.get(':org/team/list_teams', done, fail),
		getUserDetails: (done, fail = null) => this.get(':org/team/get_user_details', done, fail),

		newTeam: (done, fail = null) => this.get(':org/team/new_team', done, fail),
		getTeam: (team_id, done, fail = null) => this.get(':org/team/get_team?team_id=' + team_id, done, fail),
		saveTeam: (data, done, fail = null) => this.post(':org/team/save_team', data, done, fail),
		archiveTeam: (team_id, done, fail = null) => this.get(':org/team/archive_team?team_id=' + team_id, done, fail),
		saveUser: (data, done, fail = null) => this.post(':org/team/save_user', data, done, fail),
		getUser: (user_id, done, fail = null) => this.get(':org/team/get_user?user_id=' + user_id, done, fail),
	};

	snagg = {
		getSnaggs: (options, done, fail = null) => this.post(':org/snagg/list_snaggs', options || {}, done, fail),
		deleteSnagg: (data, done, fail = null) => this.post(':org/snagg/delete_snaggs', data, done, fail),
		getSnaggCategories: (done, fail = null) => this.get(':org/snagg/get_snag_categories', done, fail),
		addSnaggCategory: (data, done, fail = null) => this.post(':org/snagg/add_snag_category', data, done, fail),
		saveSnaggReportData: (data, done, fail = null) => this.post(':org/snagg/save_snagg_report_data', data, done, fail),
	};

	task = {
		list: (options, done, fail = null) => this.post(':org/task/list', options || {}, done, fail),
		get: (id, done, fail = null) => this.get(':org/task/get?id=' + id, done, fail),
		new: (data, done, fail = null) => this.post(':org/task/new', data, done, fail),
		save: (data, done, fail = null) => this.post(':org/task/save', data, done, fail),
		delete: (ids, done, fail = null) => this.post(':org/task/delete', ids, done, fail),
		getFilterData: (options, done, fail = null) => this.post(':org/task/get_filter_data', options || {}, done, fail),
		details: (id, done, fail = null) => this.get(':org/task/details?id=' + id, done, fail),
		markAsCompleted: (id, done, fail = null) => this.get(':org/task/mark_as_completed?id=' + id, done, fail),
		markAsActive: (id, done, fail = null) => this.get(':org/task/mark_as_active?id=' + id, done, fail),
		getSubTask: (task_id, done, fail = null) => this.get(':org/task/get_subtask?task_id=' + task_id, done, fail),
		getMaterialList: (task_id, done, fail = null) => this.get(':org/task/get_material_list?task_id=' + task_id, done, fail),
		createManualTask: (data, done, fail = null) => this.post(':org/task/create_manual_task', data, done, fail),
		newManualTask: (data, done, fail = null) => this.post(':org/task/new_manual_task', data, done, fail),
		getProjectAddress: (id, done, fail = null) => this.get(':org/task/get_project_address?id=' + id, done, fail),

		listPhases: (done, fail = null) => this.get(':org/task/list_phases', done, fail),
		getPhase: (id, done, fail = null) => this.get(':org/task/get_phase?id=' + id, done, fail),
		newPhase: (done, fail = null) => this.get(':org/task/new_phase', done, fail),
		savePhase: (data, done, fail = null) => this.post(':org/task/save_phase', data, done, fail),
		deletePhase: (id, done, fail = null) => this.get(':org/task/delete_phase?id=' + id, done, fail),

		getTaskBuilderProject: (id, done, fail = null) => this.get(':org/task/get_task_builder_project?id=' + id, done, fail),
		getTaskBuilderQuote: (id, done, fail = null) => this.get(':org/task/get_task_builder_quote?id=' + id, done, fail),
		getTaskBuilderLines: (options, done, fail = null) => this.post(':org/task/get_task_builder_lines', options, done, fail),
		validateTaskBuilderLines: (data, done, fail = null) => this.post(':org/task/validate_task_builder_lines', data, done, fail),
		setQuoteLineIgnore: (data, done, fail = null) => this.post(':org/task/set_quote_line_ignore', data, done, fail),

		listWorkOrders: (options, done, fail = null) => this.post(':org/task/list_work_orders', options || {}, done, fail),
		createWorkOrder: (data, done, fail = null) => this.post(':org/task/create_work_order', data, done, fail),
		getWorkOrderDetails: (options, done, fail = null) => this.post(':org/task/get_work_order_details', options || {}, done, fail),
		getWorkOrderTasks: (id, done, fail = null) => this.get(':org/task/get_work_order_tasks?id=' + id, done, fail),
		saveWorkOrder: (data, done, fail = null) => this.post(':org/task/save_work_order', data, done, fail),
		scheduleWorkOrder: (data, done, fail = null) => this.post(':org/task/schedule_work_order', data, done, fail),
		deleteWorkOrder: (id, done, fail = null) => this.get(':org/task/delete_work_order?id=' + id, done, fail),
		addTaskToWorkOrder: (data, done, fail = null) => this.post(':org/task/add_task_to_work_order', data, done, fail),
		removeTaskFromWorkOrder: (data, done, fail = null) => this.post(':org/task/remove_task_from_work_order', data, done, fail),
		changeWorkOrderStage: (id, stage, done, fail = null) => this.get(':org/task/change_work_order_stage?id=' + id + '&stage=' + stage, done, fail),

		listDependencies: (done, fail = null) => this.get(':org/task/list_dependencies', done, fail),
		getDependency: (id, done, fail = null) => this.get(':org/task/get_dependency?id=' + id, done, fail),
		newDependency: (done, fail = null) => this.get(':org/task/new_dependency', done, fail),
		saveDependency: (data, done, fail = null) => this.post(':org/task/save_dependency', data, done, fail),
		deleteDependency: (id, done, fail = null) => this.get(':org/task/delete_dependency?id=' + id, done, fail),
		listDependencyOutcomes: (options, done, fail = null) => this.post(':org/task/list_dependency_outcomes', options || {}, done, fail),
		saveDependencyReportData: (data, done, fail = null) => this.post(':org/task/save_dependency_report_data', data, done, fail),

		getTimeEntry: (id, done, fail = null) => this.get(':org/task/get_time_entry?id=' + id, done, fail),
		getBreakEntry: (id, done, fail = null) => this.get(':org/task/get_break_entry?id=' + id, done, fail),
		saveTimeEntry: (data, done, fail = null) => this.post(':org/task/save_time_entry', data, done, fail),
		saveBreakEntry: (data, done, fail = null) => this.post(':org/task/save_break_entry', data, done, fail),
		deleteBreakEntry: (id, done, fail = null) => this.get(':org/task/delete_break_entry?id=' + id, done, fail),
		deleteTimeEntry: (id, done, fail = null) => this.get(':org/task/delete_time_entry?id=' + id, done, fail),
		listTimeEntries: (options, done, fail = null) => this.post(':org/task/list_time_entries', options || {}, done, fail),
		listActiveWorkingUsers: (options, done, fail = null) => this.post(':org/task/list_active_working_users', options || {}, done, fail),
		getWeekwiseTimeEntryInsights: (done, fail = null) => this.get(':org/task/get_weekwise_time_entry_insights', done, fail),
		listWeekwiseTimeEntries: (done, fail = null) => this.get(':org/task/list_weekwise_time_entries', done, fail),
		listDaywiseTimeEntries: (options, done, fail = null) => this.post(':org/task/list_daywise_time_entries', options || {}, done, fail),

		listWorkOrderProjects: (done, fail = null) => this.get(':org/task/list_work_order_projects', done, fail),
		overviewSummary: (id, done, fail = null) => this.get(':org/task/overview_summary?id=' + id, done, fail),
		storeTaskDependency: (data, done, fail = null) => this.post(':org/task/store_task_dependency', data, done, fail),

		getSchedule: (data, done, fail = null) => this.post(':org/task/get_schedule', data, done, fail),
		getScheduleTasks: (data, done, fail = null) => this.post(':org/task/get_schedule_tasks', data, done, fail),

		listTaskTemplates: (done, fail = null) => this.get(':org/task/list_task_templates', done, fail),
		createTaskTemplate: (data, done, fail = null) => this.post(':org/task/create_task_template', data, done, fail),
		deleteTaskTemplate: (id, done, fail = null) => this.get(':org/task/delete_task_template?id=' + id, done, fail),

		newAllocation: (data, done, fail = null) => this.post(':org/task/new_allocation', data, done, fail),
		getAllocation: (id, done, fail = null) => this.get(':org/task/get_allocation?id=' + id, done, fail),
		saveAllocation: (data, done, fail = null) => this.post(':org/task/save_allocation', data, done, fail),
		splitAllocation: (data, done, fail = null) => this.post(':org/task/split_allocation', data, done, fail),
		deleteAllocation: (data, done, fail = null) => this.post(':org/task/delete_allocation', data, done, fail),
		markUserDate: (data, done, fail = null) => this.post(':org/task/mark_user_date', data, done, fail),
		getAllocationData: (data, done, fail = null) => this.post(':org/task/get_allocation_data', data, done, fail),
		getScheduleDropdowns: (done, fail = null) => this.get(':org/task/get_schedule_dropdowns', done, fail),
		getAllocationHours: (data, done, fail = null) => this.post(':org/task/get_allocation_hours', data, done, fail)
	};

	calculate = {
		margin: (price, cost) => {
			price = DecimalPipe.parse(price);
			cost = DecimalPipe.parse(cost);
			const profit = price - cost;
			return price === 0 ? 0 : profit / price * 100;
		},

		markup: (price, cost) => {
			price = DecimalPipe.parse(price);
			cost = DecimalPipe.parse(cost);
			const profit = price - cost;
			return cost === 0 ? 0 : profit / cost * 100;
		},

		profit: (price, cost) => {
			price = DecimalPipe.parse(price);
			cost = DecimalPipe.parse(cost);
			return price - cost;
		}
	};

	globalSearch = {
		searchProducts: (searchTerm, done, fail = null) => this.get(':org/globalsearch/searchProducts?query=' + searchTerm, done, fail),
	};

	wizard = {
		listIntroPages: (options, done, fail = null) => this.post(':org/wizard/list_intro_pages', options || {}, done, fail),
		getIntroPage: (id, done, fail = null) => this.get(':org/wizard/get_intro_page?id=' + id, done, fail),
		getIntroPageByLocation: (options, done, fail = null) => this.post(':org/wizard/get_intro_page_by_location', options || {}, done, fail),
		newIntroPage: (done, fail = null) => this.get(':org/wizard/new_intro_page', done, fail),
		saveIntroPage: (data, done, fail = null) => this.post(':org/wizard/save_intro_page', data, done, fail),
		archiveIntroPage: (id, done, fail = null) => this.get(':org/wizard/archive_intro_page?id=' + id, done, fail),
		unarchiveIntroPage: (id, done, fail = null) => this.get(':org/wizard/unarchive_intro_page?id=' + id, done, fail),
		getRoutes: (done, fail = null) => this.get(':org/wizard/get_routes', done, fail),
		dismissIntroPage: (id, done, fail = null) => this.get(':org/wizard/dismiss_intro_page?infographic_id=' + id, done, fail),
		onboarding: (done, fail = null) => this.get(':org/wizard/onboarding', done, fail),
		saveOnboarding: (data, done, fail = null) => this.post(':org/wizard/save_onboarding', data, done, fail),
		finaliseOnboarding: (done, fail = null) => this.get(':org/wizard/finalise_onboarding', done, fail),
		onboardingAccounts: (done, fail = null) => this.get(':org/wizard/onboarding_accounts', done, fail),
		onboardingQuestions: (done, fail = null) => this.get(':org/wizard/onboarding_questions', done, fail),
		onboardingSystems: (done, fail = null) => this.get(':org/wizard/onboarding_systems', done, fail),
		newOnboardingSystem: (done, fail = null) => this.get(':org/wizard/new_onboarding_system', done, fail),
		getOnboardingSystem: (id, done, fail = null) => this.get(':org/wizard/get_onboarding_system?id=' + id, done, fail),
		saveOnboardingSystem: (data, done, fail = null) => this.post(':org/wizard/save_onboarding_system', data, done, fail),
		deleteOnboardingSystem: (id, done, fail = null) => this.get(':org/wizard/delete_onboarding_system?id=' + id, done, fail),
		getOnboardingQuestion: (id, done, fail = null) => this.get(':org/wizard/get_onboarding_question?id=' + id, done, fail),
		saveOnboardingQuestion: (data, done, fail = null) => this.post(':org/wizard/save_onboarding_question', data, done, fail),
		getOnboardingDetails: (id, done, fail = null) => this.get(':org/wizard/get_onboarding_details?id=' + id, done, fail),
		changeOnboardingStatus: (data, done, fail = null) => this.post(':org/wizard/change_onboarding_status', data, done, fail)
	};

	private refreshTimer;

	constructor(
		private http: HttpClient,
		private app: AppService,
		private router: Router,
		private translate: TranslateService
	) {
		this.refreshUserInfo();

		this.general.listCountries(list => {
			this.app.countries = list;
		});
	}

	private getJsonError(error) {
		try {
			if (error?.status === 401) {
				if (this.app.routeData.auth) this.logout();
				return { message: error.error.message };
			} else if (error.error instanceof ErrorEvent) {
				return { message: error.error.message };
			} else if (Mangler.isObject(error.error)) {
				return error.error;
			} else {
				if (error.status) {
					return { message: 'Internal server error.' };
				} else {
					return { message: 'Connection error.' };
				}
			}
		} catch (ex) {
			return { message: 'Internal server error.' };
		}
	}

	private get(action, done = null, fail = null) {
		action = ('' + action).replace(':org', this.app.org);

		this.http.get('/api/' + action, { withCredentials: true, observe: 'response' }).subscribe(response => {
			const buildVersion = response.headers.get('X-Build');
			this.app.buildVersion = buildVersion || 'UNKNOWN';
			done?.(response.body);
		}, error => {
			const buildVersion = error?.headers?.get('X-Build');
			if (buildVersion) this.app.buildVersion = buildVersion || 'UNKNOWN';
			fail?.(this.getJsonError(error));
		});
	}

	private post(action, data = {}, done = null, fail = null) {
		action = ('' + action).replace(':org', this.app.org);

		this.http.post('/api/' + action, data, { withCredentials: true, observe: 'response' }).subscribe(response => {
			const buildVersion = response.headers.get('X-Build');
			this.app.buildVersion = buildVersion || 'UNKNOWN';
			done?.(response.body);
		}, error => {
			const buildVersion = error?.headers?.get('X-Build');
			if (buildVersion) this.app.buildVersion = buildVersion || 'UNKNOWN';
			fail?.(this.getJsonError(error));
		});
	}

	public objectToQueryString(obj) {
		if (!Mangler.isObject(obj)) return '';

		const qs = [];
		Mangler.each(obj, (k, v) => {
			qs.push(k + '=' + encodeURIComponent(v));
		});
		return qs.join('&');
	}

	logout() {
		this.app.userName = '';
		this.app.userEmail = '';
		this.app.userId = null;
		this.app.userImage = '';

		const finished = () => {
			this.router.navigate(['/auth/login']);
		};

		this.public.logout(finished, finished);
	}

	refreshUserInfo(doneCallback = null) {
		clearTimeout(this.refreshTimer);

		const refreshOrg = () => {
			if (this.app.org) {
				this.organisation.getInfo(info => {
					if (info.alias === this.app.org) {
						this.app.orgInfo = info;

						this.translate.use(this.app.orgInfo.date_locale);
						if (this.app.orgInfo?.calendar_date_format) this.app.calendarDateFormat = this.app.orgInfo.calendar_date_format;
						this.app.switchTheme(info.app_theme);

						const vatTranslation = this.app.orgInfo.translation_list.find(item => item.term === 'VAT');
						if (vatTranslation) {
							this.app.translatedVATTerm = vatTranslation.translated_term;
						}

						this.app.refreshTitle();
					}

					// Refresh timer periodically if it's a sandbox account
					if (info.is_sandbox) {
						this.refreshTimer = setTimeout(() => this.refreshUserInfo(), 1800000);
					}

					doneCallback?.();
				}, () => {
					doneCallback?.();
				});
			}
		};

		this.general.getUserInfo(userInfo => {
			this.app.userName = userInfo.user_name;
			this.app.userEmail = userInfo.user_email;
			this.app.userId = userInfo.user_id;
			this.app.userImage = userInfo.image_url;

			refreshOrg();
		}, () => {
			this.app.userName = '';
			this.app.userEmail = '';
			this.app.userId = null;
			this.app.userImage = '';

			refreshOrg();
		});
	}

	refreshAlerts() {
		if (this.app.org) {
			this.alert.count(count => {
				this.app.alertCount = count;
			});
		} else {
			this.app.alertCount = 0;
		}
	}

}
